<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="700px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Inscrito
          <span v-if="enrolled" class="body-2">
            #{{ enrolled.id }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loadingEnrolled"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-switch
            v-model="canEdit"
            class="mt-3 mr-5"
            dense
            inset
          >
            <template v-slot:label>
              <small class="ml-n3">
                Habilitar edição
              </small>
            </template>
          </v-switch>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4" v-if="enrolled">
        <v-row>
          <v-col>
            <v-btn
              color="teal accent-4"
              class="mr-2"
              outlined
              small
              @click="confirm"
              :disabled="!!enrolled.showedUpDate"
              :loading="loadingConfirm"
            >
              <v-icon
                v-text="'mdi-account-check'"
                left
              />
              {{ enrolled.showedUpDate ? 'Presença confirmada' : 'Confirmar presença' }}
            </v-btn>
            <v-btn
              outlined
              small
              class="mr-2"
              @click="downloadBadge"
              :loading="loadingBadge"
            >
              <v-icon
                v-text="'mdi-badge-account-horizontal-outline'"
                left
              />
              Crachá
            </v-btn>
            <v-btn
              outlined
              small
              class="mr-2"
              @click="downloadCertificate"
              :loading="loadingCertificate"
            >
              <v-icon
                v-text="'mdi-certificate-outline'"
                left
              />
              Certificado
            </v-btn>
            <v-btn
              outlined
              small
              @click="certificateEmail"
              :loading="loadingCertificateEmail"
            >
              <v-icon
                v-text="'mdi-send'"
                left
              />
              Enviar e-mail certificado
            </v-btn>
          </v-col>
        </v-row>
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="enrolled.name"
                  hide-details="auto"
                  label="Nome"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="enrolledTypeId"
              >
                <v-select
                  v-model="enrolled.enrolledTypeId"
                  :items="enrolledTypes"
                  item-value="id"
                  item-text="description"
                  label="Tipo"
                  hide-details="auto"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="nickname"
              >
                <v-text-field
                  v-model="enrolled.nickname"
                  hide-details="auto"
                  label="Apelido"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="cityId"
              >
                <v-autocomplete
                  v-model="enrolled.cityId"
                  :items="cities"
                  item-value="id"
                  item-text="name"
                  label="Cidade"
                  hide-details="auto"
                  autocomplete="nope"
                  auto-select-first
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="phoneNumber"
              >
                <v-text-field
                  v-model="enrolled.phoneNumber"
                  hide-details="auto"
                  label="Telefone"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="email"
              >
                <v-text-field
                  v-model="enrolled.email"
                  hide-details="auto"
                  label="E-mail"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="occupation"
              >
                <v-text-field
                  v-model="enrolled.occupation"
                  hide-details="auto"
                  label="Formação/Cargo/Setor"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="companyName"
              >
                <v-text-field
                  v-model="enrolled.companyName"
                  hide-details="auto"
                  label="Instituição/Empresa"
                  :readonly="!canEdit"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
        <v-row class="mt-5">
          <v-col>
            <b>Data inscrição: </b> {{ enrolled.createdAt | date('DD/MM/YYYY HH:mm') }} <br>
          </v-col>
          <v-col>
            <b>Data presença: </b> {{ enrolled.showedUpDate | date('DD/MM/YYYY HH:mm') }} <br>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>Sequência: </b> {{ enrolled.sequence | '-' }} <br>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          :disabled="!canEdit"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import enrolledApi from '@/api/enrolled'
  import enrolledTypesApi from '@/api/enrolled-types'
  import citiesApi from '@/api/cities'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      eventId: {
        type: Number,
        default: 0,
      },
      enrolledId: {
        type: Number,
        default: 0,
      },
      downloadBadgeOnConfirm: {
        type: Boolean,
        default: false,
      }
    },

    data () {
      return {
        loadingEnrolled: false,
        loadingConfirm: false,
        loadingBadge: false,
        loadingCertificate: false,
        loadingCertificateEmail: false,
        loadingEnrolledTypes: false,
        loadingCities: false,
        loadingSave: false,
        canEdit: false,
        enrolled: null,
        enrolledTypes: [],
        cities: [],
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show: {
        immediate: true,
        handler (show) {
          if (show && this.enrolledId && this.eventId) {
            this.loadEnrolled()
            this.loadEnrolledTypes()
            this.loadCities()
          }
        },
      },
    },

    methods: {

      async loadEnrolled () {
        try {
          this.loadingEnrolled = true
          const response = await enrolledApi.get(this.eventId, this.enrolledId)
          this.enrolled = response.data.enrolled
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingEnrolled =false
        }
      },

      async save () {
        try {
          this.loadingSave = true
          await enrolledApi.update(this.eventId, this.enrolledId, this.enrolled)
          this.canEdit = false
          this.$snackbar.show({ color: 'success', message: this.$messages._('update_success') })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave =false
        }
      },

      async loadEnrolledTypes () {
        try {
          this.loadingEnrolledTypes = true
          const response = await enrolledTypesApi.list()
          this.enrolledTypes = response.data.enrolledTypes
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingEnrolledTypes =false
        }
      },

      async loadCities () {
        try {
          this.loadingCities = true
          const response = await citiesApi.list()
          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingCities =false
        }
      },

      async confirm () {
        try {
          this.loadingConfirm = true
          const response = await enrolledApi.confirmPresence(this.eventId, this.enrolled?.id)
          this.enrolled = response.data.enrolled

          if (this.downloadBadgeOnConfirm) {
            await this.downloadBadge()
          }

          this.$emit('confirm-presence')
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingConfirm =false
        }
      },

      async downloadBadge () {
        try {
          this.loadingBadge = true
          const response = await enrolledApi.badge(this.eventId, this.enrolled?.id)

          const linkSource = `data:application/pdf;base64,${response.data.pdf}`
          const downloadLink = document.createElement('a')
          const fileName = `cracha_${this.enrolled.id}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingBadge = false
        }
      },

      async downloadCertificate () {
        try {
          this.loadingCertificate = true
          const response = await enrolledApi.certificate(this.eventId, this.enrolled?.id)

          const linkSource = `data:application/pdf;base64,${response.data.pdf}`
          const downloadLink = document.createElement('a')
          const fileName = `certificado_${this.enrolled.id}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingCertificate = false
        }
      },

      async certificateEmail () {
        try {
          this.loadingCertificateEmail = true
          await enrolledApi.certificateSendEmail(this.eventId, this.enrolled.id)
        } catch (e) {
          this.$snackbar.show({ color: e?.response?.status === 400 ? 'warning' : 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingCertificateEmail =false
        }
      },

    },

  }
</script>
